<template>
  <div class="HSMask">
    <div class="HSDialog">
      <div class="HSDialogTitleLine">
        <div class="HSDialogTitle">修改密码</div>
        <img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close" />
      </div>
      <!-- //////////////////////////////////////////////////////////////////////////////// -->
      <el-form style="padding:20px 30px;box-sizing: border-box;height:300px;overflow: auto;" label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="原密码" required>
              <el-input type="password" v-model.trim="oldpwd"></el-input>
            </el-form-item>
          </el-col>
         
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="新密码" required>
              <el-input type="password" v-model.trim="newpwd"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="确认密码" required>
              <el-input type="password" v-model.trim="pwd"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
       
      </el-form>
      <div style="height: 80px;"></div>
      <div class="HSDialogButtonLine">
        <div class="HSDialogButtonConfirm" @click="submit">保存</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 顾客
      oldpwd: '',
      newpwd: '',
      pwd: '',
    }
  },
  computed: {
    userInfo() {
			return this.$store.state.userInfo
		},
  },
  mounted() {
  },
  methods: {
    // 关闭
    close() {
      this.$emit("submit", { type: "close", data: "" })
    },
    // 提交
    submit() {
      if (this.$public.isNull(this.oldpwd)) {
        this.$message({
          message: '请输入原密码',
          type: 'error'
        });
        return;
      }
      if (this.$public.isNull(this.newpwd)) {
        this.$message({
          message: '请输入新密码',
          type: 'error'
        });
        return;
      }
      if (this.newpwd!==this.pwd) {
        this.$message({
          message: '两次密码输入不一致',
          type: 'error'
        });
        return;
      }
        let params = {
          userid:this.userInfo.id,
          newpwd:this.newpwd,
          oldpwd:this.oldpwd

         }
        this.$http.get("/cms/StaffInfo/UpdateStaffPwd", params).then(res => {
          if (res.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success'
            });
            this.$emit("submit", { type: 'success', data: res.data })
          }
        })
      }
    },
};
</script>
<style lang="scss" scoped></style>
