<template>
	<div class="comp_tabNav">
		<el-tabs v-model="title" @tab-click="handleClick">
			<template v-for="item in list">
				<el-tab-pane v-if="$buttonAuthority(item.code)" :name="item.title" :key="item.code">
					<div slot="label" @click="clickChange(item)" :class="item.title === title ? 'active' : ''"
						style="position: relative;">
						{{ item.title }}
						<div class="dot" v-if="item.num !== undefined && item.num !== 0">
							{{ item.num }}
						</div>
					</div>
				</el-tab-pane>
			</template>
			<el-tab-pane style="width:0;height: 0;" label="first" name="first"></el-tab-pane>
		</el-tabs>
	</div>
</template>
<script>
export default {
	data() {
		return {
			title: '',
			item: ""
		}
	},
	props: {
		list: {
			default: () => {
				return []
			},
		},
		curItem: {
			default: () => {
				return {}
			}
		},
	},
	watch: {
		curItem(n) {
			this.title = n.title;
		}
	},
	mounted() {
		this.routeChange()
	},
	methods: {
		routeChange() {
			let indexFlag = 0
			console.log(this.list,'this.list');
			indexFlag = this.list.findIndex(item=> this.$buttonAuthority(item.code))
			console.log(indexFlag,'indexFlag');
			this.title = this.list[indexFlag].title;
			this.item = this.list[indexFlag]
			this.$emit('change', this.item)
		},
		clickChange(item) {
			this.item = item
		},
		handleClick() {
			setTimeout(() => {
				this.$emit('change', this.item)
			}, 100);
		}
	}
}
</script>
<style scoped lang="scss">
.comp_tabNav {
	height: 70px;

	::v-deep.el-tabs {
		height: 70px;

		.el-tabs__header {
			margin: 0 0 0;
		}

		.el-tabs__active-bar {
			background-color: $mainColor;
		}

		.el-tabs__item {
			line-height: 70px;
			height: 70px;
			font-size: 16px;
			color: $fontColor;
			padding: 0 30px;

			.active {
				position: relative;
				color: $mainColor;
			}

			.dot {
				display: inline-block;
				position: absolute;
				top: 12px;
				right: -16px;
				background-color: #F56C6C;
				border-radius: 10px;
				height: 20px;
				min-width: 12px;
				line-height: 20px;
				color: #fff;
				font-size: 12px;
				text-align: center;
				padding: 0 4px;
			}

			&#tab-first {
				padding: 0;
				width: 0;
				height: 0;
				overflow: hidden;
			}
		}

		.el-tabs__nav-wrap::after {
			background-color: transparent;
		}
	}
}
</style>
